<template>
  <div
    class="bm-container flex justify-center relative"
    @mouseenter="
      setCursor(null, null, {
        'font-size': '14px',
        background: '#FFF',
        width: '25px',
        height: '25px',
        'z-index': '1',
        position: 'absolute',
      })
    "
  >
    <div class="max-w-7xl mb-3 lg:mb-20 relative z-40">
      <div class="flex justify-center mt-20 lg:mt-24 text-center px-6">
        <h2 class="h1">{{ $t("homepage.becomeMember.title") }}</h2>
      </div>
      <!--          //TODO -->
      <div class="txt-l flex justify-center p-6 text-center">
        {{ $t("homepage.becomeMember.subtitle.part1") }}<br />
        {{ $t("homepage.becomeMember.subtitle.part2") }}<br />
        {{ $t("homepage.becomeMember.subtitle.part3") }}<br />
        {{ $t("homepage.becomeMember.subtitle.part4") }}
      </div>

      <div class="flex justify-center xl:pt-24 text-center xl:hidden">
        <button
          class="pointer-events-auto whitespace-nowrap bg-transparent border rounded-full border-black px-4 py-2 text-sm mb-6"
          @click="scrollTo"
        >
          {{ $t("spaces.meetingrooms.moreInfo") }}
        </button>
      </div>

      <div
        class="md:grid md:grid-cols-2 lg:grid-cols-4 gap-8 px-6 md:p-12 text-center content-center"
      >
        <div
          class="lg:cursor-none"
          @mouseenter="
            setCursor($t('more-about-membership'), scrollTo, {
              'font-size': '14px',
              background: '#FFF',
              width: '100px',
              height: '100px',
              cursor: 'pointer',
            })
          "
          @mouseleave="
            setCursor(null, scrollTo, {
              'font-size': '14px',
              background: '#FFF',
              width: '25px',
              height: '25px',
              'z-index': '1',
              cursor: 'unset',
            })
          "
        >
          <div class="flex justify-center lg:cursor-none">
            <AnimationPaper />
          </div>
          <h3 class="p-1 mt-6">
            {{ $t("homepage.becomeMember.connect.title") }}
          </h3>
          <div class="p-2 mb-4 lg:mb-0 bm-description">
            {{ $t("homepage.becomeMember.connect.subtitle") }}
          </div>
        </div>
        <div
          class="lg:cursor-none"
          @mouseenter="
            setCursor($t('more-about-membership'), scrollTo, {
              'font-size': '14px',
              background: '#FFF',
              width: '100px',
              height: '100px',
              cursor: 'pointer',
            })
          "
          @mouseleave="
            setCursor(null, scrollTo, {
              'font-size': '14px',
              background: '#FFF',
              width: '25px',
              height: '25px',
              'z-index': '1',
              cursor: 'unset',
            })
          "
        >
          <div class="flex justify-center">
            <AnimationPaperMeet />
          </div>
          <h3 class="p-1 mt-6">{{ $t("homepage.becomeMember.meet.title") }}</h3>
          <div class="p-2 mb-4 lg:mb-0 bm-description">
            {{ $t("homepage.becomeMember.meet.subtitle") }}
          </div>
        </div>
        <div
          class="lg:cursor-none"
          @mouseenter="
            setCursor($t('more-about-membership'), scrollTo, {
              'font-size': '14px',
              background: '#FFF',
              width: '100px',
              height: '100px',
              cursor: 'pointer',
            })
          "
          @mouseleave="
            setCursor(null, scrollTo, {
              'font-size': '14px',
              background: '#FFF',
              width: '25px',
              height: '25px',
              'z-index': '1',
              cursor: 'unset',
            })
          "
        >
          <div class="flex justify-center">
            <AnimationPaperSeat />
          </div>
          <h3 class="p-1 mt-6">{{ $t("homepage.becomeMember.seat.title") }}</h3>
          <div class="p-2 mb-4 lg:mb-0 bm-description">
            {{ $t("homepage.becomeMember.seat.subtitle") }}
          </div>
        </div>
        <div
          class="lg:cursor-none"
          @mouseenter="
            setCursor($t('more-about-membership'), scrollTo, {
              'font-size': '14px',
              background: '#FFF',
              width: '100px',
              height: '100px',
              cursor: 'pointer',
            })
          "
          @mouseleave="
            setCursor(null, scrollTo, {
              'font-size': '14px',
              background: '#FFF',
              width: '25px',
              height: '25px',
              'z-index': '1',
              cursor: 'unset',
            })
          "
        >
          <div class="flex justify-center">
            <AnimationPaperInfinite />
          </div>
          <h3 class="p-1 mt-6">
            {{ $t("homepage.becomeMember.ownTable.title") }}
          </h3>
          <div class="p-2 bm-description">
            {{ $t("homepage.becomeMember.ownTable.subtitle") }}
          </div>
        </div>
      </div>

      <div class="flex xl:hidden justify-center mt-8 xl:pt-24 text-center">
        <button
          class="pointer-events-auto whitespace-nowrap bg-transparent border rounded-full border-black px-4 py-2 text-sm xl:hidden"
          @click="scrollTo"
        >
          {{ $t("spaces.meetingrooms.moreInfo") }}
        </button>
      </div>

      <div class="md:flex px-8 pt-24 gap-8 justify-center">
        <div
          class="flex items-center flex-col xl:flex-row lg:cursor-none"
          @mouseenter="
            setCursor($t('more-about-membership'), scrollTo, {
              'font-size': '14px',
              background: '#FFF',
              width: '100px',
              height: '100px',
              cursor: 'pointer',
            })
          "
          @mouseleave="
            setCursor(null, scrollTo, {
              'font-size': '14px',
              background: '#FFF',
              width: '25px',
              height: '25px',
              'z-index': '1',
              cursor: 'unset',
            })
          "
        >
          <img class="ml-auto mr-auto max-w-xs" src="/img/kancelar.jpg" />
          <div class="pt-6 md:pt-12 md:p-12 pb-16 md:pb-12 max-w-sm">
            <div class="flex">
              <img class="h-6 mr-3" src="../assets/icon-infinite.png" />
              <div class="become-member-text">
                2 - 8 {{ $t("homepage.becomeMember.membersIncluded.title") }}
              </div>
            </div>

            <div class="mt-8 mb-2 become-member-text">
              <h3>{{ $t("homepage.becomeMember.office.title") }}</h3>
            </div>
            <div class="txt-l become-member-text">
              {{ $t("homepage.becomeMember.office.subtitle") }}
            </div>
          </div>
        </div>
        <div
          class="flex items-center flex-col xl:flex-row lg:cursor-none"
          @mouseenter="
            setCursor($t('more-about-membership'), scrollTo, {
              'font-size': '14px',
              background: '#FFF',
              width: '100px',
              height: '100px',
              cursor: 'pointer',
            })
          "
          @mouseleave="
            setCursor(null, scrollTo, {
              'font-size': '14px',
              background: '#FFF',
              width: '25px',
              height: '25px',
              'z-index': '1',
              cursor: 'unset',
            })
          "
        >
          <img class="max-w-xs ml-auto mr-auto" src="/img/coworking.jpg" />
          <div class="pt-6 md:pt-12 md:p-12 pb-16 md:pb-12 max-w-sm">
            <div class="flex">
              <img class="h-6 mr-3" src="../assets/icon-infinite.png" />
              <div></div>
            </div>
            <div class="mt-8 mb-2 become-member-text">
              <h3>{{ $t("homepage.becomeMember.dayPass.title") }}</h3>
            </div>
            <div class="txt-l become-member-text">
              {{ $t("homepage.becomeMember.dayPass.subtitle") }}
            </div>

            <div
              class="flex md:hidden justify-center mt-12 lg:pt-24 text-center"
            >
              <button
                class="pointer-events-auto whitespace-nowrap bg-transparent border rounded-full border-black px-4 py-2 text-sm md:hidden"
                @click="scrollTo"
              >
                {{ $t("more-about-membership") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BecomeMember",
  props: {
    setCursor: {
      required: true,
      type: Function,
    },
  },
  methods: {
    scrollTo() {
      return navigateTo(this.localePath({ name: "membership" }));
    },
  },
};
</script>

<style scoped lang="scss">
.bm-container {
  background-color: $color-primary;
}

.become-member-text {
  font-size: 16px;
  @media screen and (max-width: 768px) {
    max-width: 290px;
  }
}

.bm-description {
  color: #1a1a1a;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px; /* 130% */
}
</style>
